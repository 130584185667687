import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import './Location.css'

function Location() {
    return (
        <>
        <div className='location-container'>
            <h1>Locations</h1>
            <div className="row" style={{width: '100vw'}}>
                <div className="col-md-6 desktop-only">
                    <ul style={{ "padding-left":"0px"}}>
                        
<li>CORONA DEL MAR (CA)</li>
<li>SCOTTSDALE (AZ)</li>
<li>WHITTIER (CA)</li>
<li>CARLSBAD (CA)</li>
<li>SAN CLEMENTE (CA)</li>
<li>RANCHO BERNARDO (CA)</li>
<li>ANAHEIM (CA)</li>
<li>HILLCREST (CA)</li>
<li>HUNTINGTON BEACH (CA)</li>
                    </ul>
                </div>
                <div className="col-md-6 desktop-only">
                    <ul style={{ "padding-left":"0px"}}>
<li>LA COSTA (CA)</li>
<li>LA MESA (CA)</li>
<li>LAGUNA NIGUEL (CA)</li>
<li>MESA (AZ)</li>
<li>MISSION VIEJO NORTH (CA)</li>
<li>MISSION VIEJO SOUTH (CA)</li>
<li>RANCHO PENAQUITOS (CA)</li>
<li>RIVERSIDE (CA)</li>
<li>UPLAND (CA)</li>
<li>VALENCIA (CA)</li>
                    </ul>
                </div>
                <div class="mobile-only">
                <ul style={{ "padding-left":"0px"}}>
                        
                        <li>CORONA DEL MAR (CA)</li>
                        <li>SCOTTSDALE (AZ)</li>
                        <li>WHITTIER (CA)</li>
                        <li>CARLSBAD (CA)</li>
                        <li>SAN CLEMENTE (CA)</li>
                        <li>RANCHO BERNARDO (CA)</li>
                        <li>ANAHEIM (CA)</li>
                        <li>HILLCREST (CA)</li>
                        <li>HUNTINGTON BEACH (CA)</li>
                        <li>LA COSTA (CA)</li>
<li>LA MESA (CA)</li>
<li>LAGUNA NIGUEL (CA)</li>
<li>MESA (AZ)</li>
<li>MISSION VIEJO NORTH (CA)</li>
<li>MISSION VIEJO SOUTH (CA)</li>
<li>RANCHO PENAQUITOS (CA)</li>
<li>RIVERSIDE (CA)</li>
<li>UPLAND (CA)</li>
<li>VALENCIA (CA)</li>
                                            </ul>
                </div>
            </div>
        


        </div>
        </>
    )
}

export default Location
