import React from 'react'
import './Fundraisers.css'
function Fundraisers() {
    return (
        <div>
                  <>


  <div className="fundraisers-container">
    

        
        <h1>Fundraisers</h1>
        <p>Please contact your nearest golden spoon</p>

       


      </div>


</>
        </div>
    )
}

export default Fundraisers
