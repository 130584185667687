import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import OurCustomers from '../OurCustomers';



function Customers() {
  return (
    <>
        <OurCustomers />
        <Footer />
    </>
  );
}

export default Customers;