import React from 'react'
import './Catering.css'
function Catering() {
    return (
        <div>
            
  <div className="catering-container">
    

        
    <h1>Catering</h1>
    <p>Please contact your nearest golden spoon</p>

   


  </div>
        </div>
    )
}

export default Catering
