import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import  logo  from '../images/goldenspoon/gs-logo.jpg';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <=960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize', showButton)

    return (
    <>
  <img className='logo desktop-only' src={logo} alt="s" /> 
    <nav className="navbar">
        <div className="navbar-container">
            
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            
 

            </Link>
        
            <div className="menu-icon" onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className="nav-item">
                    <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                        Home
                    </Link>
                </li>
                <li className="nav-item">
                <Link to='/history' className='nav-links' onClick={closeMobileMenu}>
                        Our Story
                    </Link>
                </li>
                <li className="nav-item">
                <Link to='/locations' className='nav-links' onClick={closeMobileMenu}>
                        Locations
                    </Link>
                </li>
                <li className="nav-item">
                <Link to='/own-a-golden-spoon' className='nav-links' onClick={closeMobileMenu}>
                        Own a Golden Spoon
                    </Link>
                </li>
                <li className="nav-item">
                <Link to='/fundraisers' className='nav-links' onClick={closeMobileMenu}>
                        Fundraisers
                    </Link>
    </li>
    <li className="nav-item">
                <Link to='/catering' className='nav-links' onClick={closeMobileMenu}>
                        Catering
                    </Link>
    </li>
   {/* <li className="nav-item">
                <Link to='/our-customers' className='nav-links' onClick={closeMobileMenu}>
                        Our Customers
                    </Link>
    </li>*/}
                <li className="nav-item">
                <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                        Contact
                    </Link>
                </li>


            </ul>

            
        </div>
    </nav>
    </>
    )
}

export default Navbar
