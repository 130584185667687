import React from 'react'
import { Button } from './Button'
import '../App.css'
import './HeroSection.css'
import logo from '../images/goldenspoon/gs-logo.jpg';

function HeroSection() {
    const str = `A CALIFORNIA 
    CLASSIC`
    
    return (
        <>
        <div className='hero-container'>
        
            <img className='logo' src={logo} alt="s" /> 
                          <h1 style={{whiteSpace: 'pre'}}> {str}</h1>
            <p>Since 1983</p>

        </div>
        </>
    );
}

export default HeroSection
