import React from 'react';
import '../../App.css';
import ContactForm from '../ContactForm';
import Footer from '../Footer';

function Contact() {
    return (
      <>
      <div className="container">
      <ContactForm/>
      </div>
      <Footer />
      </>
    );
  }
  
  export default Contact;