import React from 'react'
import './CustomerAppreciation.css'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import { Button } from './Button'
import { Link } from 'react-router-dom'

function CustomerAppreciation() {
    return (

      <div className="container">
        <div className="default-container ">

            <div className="container">
            <CardGroup >
  <Card>
    <Card.Header>
      </Card.Header>
    <div style={{ "max-height":"300px", "overflow":"hidden"}}>
    <Card.Img variant="top" src="images/goldenspoon/customers.jpg" style={{ "height":"300px"}} />
    </div>
    
    <Card.Body>
      <Card.Title>
      <Link to="/" className='nav-links'>
      <p className="center">
        We Love Our Customers</p></Link></Card.Title>
      <Card.Text>

      </Card.Text>
    </Card.Body>
    <Card.Footer>
   {/*} <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          GET STARTED
    </Button>*/}
    </Card.Footer>
  </Card>
  <Card>
    <Card.Header />
  <div style={{ "max-height":"300px", "overflow":"hidden"}}>
    <Card.Img variant="top" src="images/goldenspoon/DSC085951.jpg"  style={{"align":"middle"}}/>
    </div>
    <Card.Body>
      <Card.Title>
      <Link to='/fundraisers' className='nav-links' >
      <p className="center">Fundraisers</p>
                    </Link></Card.Title>
      <Card.Text>

      </Card.Text>
    </Card.Body>
    <Card.Footer>
    </Card.Footer>
  </Card>
  <Card>
  <Card.Header />
  <div style={{ "max-height":"300px", "overflow":"hidden"}}>
    <Card.Img variant="top" src="images/goldenspoon/DSC08869.jpg" height="300px"/>
    </div>
    <Card.Body>
      <Card.Title>
      <Link to='/own-a-golden-spoon' className='nav-links' >
      <p className="center">
        Own A Golden Spoon</p></Link></Card.Title>
      <Card.Text>

      </Card.Text>
    </Card.Body>
    <Card.Footer>

    </Card.Footer>
  </Card>
</CardGroup>

  </div>


</div>
      </div>


    
    )
}

export default CustomerAppreciation
