import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Fundraisers from '../Fundraisers';
import Location from '../Location';


function Fundraiser() {
  return (
    <>
        <Fundraisers />
        <Footer />
    </>
  );
}

export default Fundraiser;