import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import './ContactForm.css'
import { send } from 'emailjs-com';




function ContactForm() {
  const [validated, setValidated] = useState(false);

  const [toSend, setToSend] = useState({
    first_name: '',
    last_name: '',
    city: '',
    state: '',
    message: '',
    email: '',
    from_name: 'Golden Spoon'
  });
  
  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_of603f7',
      'template_soo2bar',
      toSend,
      '-blJbx9W-a5NPb0n9'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
    alert("Email Sent!");
  };
  
  
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  
  
  return (
    <>

    <div className="container">
      <div className="contact-container">
        
      <div className="row justify-content-center">
      <div className="col-md-12">
        

        <div className="row justify-content-center">
          <div className="col-md-6">
            
            <h3 className="heading mb-4">Let's talk!</h3>
            <p>Interested in owning a Golden Spoon?</p>

            <p><img src="images/goldenspoon/DSC08805.jpg" alt="Image" className="img-fluid"/></p>


          </div>
          <div className="col-md-6">
            
          <Form noValidate validated={validated} onSubmit={onSubmit}>
          <h1 className="heading">Contact Us</h1>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            name="first_name"
            type="text"
            placeholder="First name"
            defaultValue=""
            value={toSend.first_name}
            onChange={handleChange}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            name="last_name"
            type="text"
            placeholder="Last name"
            defaultValue=""
            value={toSend.last_name}
            onChange={handleChange}
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom03">
          <Form.Label>City</Form.Label>
          <Form.Control type="text" placeholder="City" name="city" value={toSend.city}
            onChange={handleChange} required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom04">
          <Form.Label>State</Form.Label>
          <Form.Control type="text" placeholder="State" name="state" value={toSend.state}
            onChange={handleChange} required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid state.
          </Form.Control.Feedback>
        </Form.Group>
        {/*<Form.Group as={Col} md="3" controlId="validationCustom05">
          <Form.Label>Zip</Form.Label>
          <Form.Control type="text" placeholder="Zip" name="zip" required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid zip.
          </Form.Control.Feedback>
  </Form.Group>*/}
      </Row>
      <Row className="mb-3">
      <Form.Group as={Col} md="6" controlId="validationCustom04">
          <Form.Label>Email Address</Form.Label>
          <Form.Control type="text" placeholder="Email" name="email" value={toSend.email}
          onChange={handleChange} required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email addrses.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Example textarea</Form.Label>
        <Form.Control as="textarea"  name="message" placeholder="Leave a comment here" value={toSend.message}
        onChange={handleChange} style={{ height: '100px' }} />
        </Form.Group>
      </Row>


      <Form.Group className="mb-3">
        <Form.Check
          required
          name="terms"
          label="Human verification check"
          feedback="You must click before submitting."
          feedbackType="invalid"
        />
      </Form.Group>
      <Button type="submit">Submit form</Button>
    </Form>

            <div id="form-message-warning mt-4"></div> 
            <div id="form-message-success">
              Your message was sent, thank you!
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>

  </div>
    </>
  );
  }
  
  export default ContactForm