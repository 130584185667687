import React from 'react'
import './Customers.css'

function OurCustomers() {
    return (
        <div>
                  <>


  <div className="customer-container">
    

        
        <h1 >Our Customers</h1>
       
        <p>We Love Our Customers</p>
       {/*} <p><img src="images/goldenspoon/customerssmall.jpg" alt="Image" className="img-fluid"/></p>*/}


      </div>



</>
        </div>
    )
}

export default OurCustomers
