import React from 'react'
import Navbar from './components/Navbar';
import Home from './components/pages/Home'
import Contact from './components/pages/Contact';
import Locations from './components/pages/Locations';
import Fundraiser from './components/pages/Fundraiser';
import History from './components/pages/History';
import OwnAGoldenSpoon from './components/pages/OwnAGoldenSpoon';
import Customers from './components/pages/Customers';
import CateringPage from './components/pages/CateringPage';
import { BrowserRouter as Router, Routes, Route, Switch} from 'react-router-dom';
import './App.css';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />}/>
        <Route path='/locations' exact element={<Locations/>}/>
        <Route path='/contact' exact element={<Contact />}/>
        <Route path='/history' exact element={<History />}/>
        <Route path='/own-a-golden-spoon' exact element={<OwnAGoldenSpoon />}/>
        <Route path='/fundraisers' exact element={<Fundraiser />}/>
        <Route path='/catering' exact element={<CateringPage />}/>
        <Route path='/our-customers' exact element={<Customers />}/>
      </Routes>
    </Router>
   
   
    </>
  );
}

export default App;


