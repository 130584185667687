import React from 'react'
import '../App.css'
import './Manufacturing.css'
import Card from 'react-bootstrap/Card'
import { Button } from './Button'
import Accordion from 'react-bootstrap/Accordion'

function HistorySection() {
    return (

        <>
<div className="container">
<div className="manufacturing-container">
  
<div className="row justify-content-center">
<div className="col-md-10">
  

  <div className="row justify-content-center">
    <div className="col-md-6 desktop-only">
      
      <h3 className="heading mb-4">Our Dairy</h3>
  
      <p><video src='/videos/manufacturing.mp4' style={{ width:"100%" }} autoPlay loop muted playsInline /></p>

    </div>
    <div className="col-md-6">
  
      <h1 className="heading mb-4">About Us</h1>
      Golden Spoon is a frozen yogurt retail chain headquartered in Rancho Santa Margarita, California with stores located in the western United States.

Our frozen yogurt uses only the freshest ingredients to produce a flavor and experience that will have you coming back for more. An experience that simply melts in your mouth.
    </div>
  </div>
</div>
</div>
</div>
<video src='/videos/goldenspoon-edited.mp4' style={{ width:"100%" }} autoPlay loop muted playsInline />
</div>
   </>   
    );
}

export default HistorySection
