import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import HistorySection from '../HistorySection';


function History() {
  return (
    <>
        <HistorySection />
        <Footer />
    </>
  );
}

export default History;


