import React from 'react'
import '../App.css'
import './Manufacturing.css'
import Card from 'react-bootstrap/Card'
import { Button } from './Button'
import Accordion from 'react-bootstrap/Accordion'
function Manufacturing() {
    return (

        <>
<video className="desktop-only" src='/videos/goldenspoon-edited.mp4' style={{ width:"100%" }} autoPlay loop muted playsInline />
<div className="container">
<div className="manufacturing-container">
  
<div className="row justify-content-center">
<div className="col-md-12">
  

  <div className="row justify-content-center">
    <div className="col-md-6">
      
      <h3 className="heading mb-4">Our Dairy</h3>
      
      

      <p><video src='/videos/manufacturing.mp4' style={{ width:"100%" }} autoPlay loop muted playsInline /></p>


    </div>
    <div className="col-md-6">
    <Card>
    <Card.Body>

      <Card.Text>
      <h1 className="heading mb-4">Our Flavors</h1>
      <ul style={{ "padding-left":"0px"}}>
      
    <li>Banana Cream Pie</li>
    <li>Black Cherry Tart Yogurt Mix</li>
    <li>Cake Batter Yogurt Mix</li>
    <li>Chocolate Yogurt Mix</li>
    <li>Classic Coffee Yogurt Mix</li>
    <li>Cookie Butter Yogurt Mix</li>
    <li>Just Chocolate Yogurt Mix</li>
    <li>Old Fashioned Vanilla Yogurt Mix</li>
    <li>Peanut Butter Cup Yogurt Mix</li>
    <li>Peanut Butter Yogurt Mix</li>
    <li>Pineapple Tart Yogurt Mix</li>
    <li>Salted Caramel Yogurt Mix</li>
    <li>Strawberry Yogurt Mix</li>
    <li>Vanilla Malt Yogurt Mix</li>
    <li>Choc Brownie Gelato Icecream</li>
    <li>St Nick's Mint Yogurt Mix *Holiday</li>
    <li>Pumpkin Yogurt Mix *Holiday</li>
</ul>
      </Card.Text>
    </Card.Body>
    <Card.Footer>
    </Card.Footer>
  </Card>
    </div>
  </div>
</div>
</div>
</div>

</div>
   </>   
    );
}

export default Manufacturing
