import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Location from '../Location';
import Manufacturing from '../Manufacturing';

function Locations() {
  return (
    <>

        <Location />

        <Footer />
    </>
  );
}

export default Locations;