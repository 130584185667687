import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ContactForm from '../ContactForm';


function OwnAGoldenSpoon() {
  return (
    <>
        <ContactForm />
        <Footer />
    </>
  );
}

export default OwnAGoldenSpoon;