import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import logo from '../images/goldenspoon/gs-logo.jpg';


function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        
      </section>
     {/*} <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items center'>
            <h2>About Us</h2>

          </div>
          <div className='footer-link-items '>
            <h2>Contact Us</h2>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items '>
            <h2>Videos</h2>
          </div>
          <div className='footer-link-items center'>
            <h2>Social Media</h2>
            <a href='https://www.instagram.com/goldenspoonyogurt/'>Instagram</a>
          </div>
        </div>
  </div>*/}
      <section className='social-media'>
          
        <div className='social-media-wrap'>
         
          <img className='footer-logo center' src={logo} alt="" />
       
          <small className='website-rights center'>Golden Spoon © 2021</small>
          <div className='social-icons'>
            <a
              className='social-icon-link instagram center'
              href='https://www.instagram.com/goldenspoonyogurt/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </a>
                     </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;