import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Catering from '../Catering';
import Location from '../Location';


function CateringPage() {
  return (
    <>
        <Catering />
        <Footer />
    </>
  );
}

export default CateringPage;