import React from 'react';
import '../../App.css';
import './css/home.css';
import ComeSpoonWithUs from '../ComeSpoonWithUs';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Manufacturing from '../Manufacturing';
import CustomerAppreciation from '../CustomerAppreciation';

import Location from '../Location';
import HistorySection from '../HistorySection';
import ContactForm from '../ContactForm';


function Home() {
  return (
    <>
      <HeroSection />
      <ComeSpoonWithUs />
      <Manufacturing />
      <CustomerAppreciation />
      <div className="mobile-only">
      <HistorySection />
      <Location />
      <ContactForm />
      </div>
      <Footer />
    </>
  );
}

export default Home;